.screenInput{
    display: flex;
    justify-content: center;
    margin: auto;

}
.result{
    /* display: flex; */
    margin: auto;
    justify-content: center;
    text-align: center;
}
.input{
    width: 25px;
    padding: 8px;
}
.container{
    display: flex;
    margin: auto;
    justify-content: center;
}
.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }
.button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }