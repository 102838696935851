.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}


input[type=number] {
  -moz-appearance: textfield;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.css-typing p {
  border-right: .15em solid orange;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

/* CSS cho type animation */
.type {
  overflow: hidden; /* Ẩn nội dung vượt ra ngoài khung */
  white-space: nowrap; /* Ngăn các phần tử con xuống dòng */
  border-right: 2px solid #000; /* Hiệu ứng con trỏ gõ code */
  animation: typing 4s steps(70, end), blink-caret 0.75s step-end infinite;
}
.type {
  overflow: hidden; /* Ẩn nội dung vượt ra ngoài khung */
  white-space: nowrap; /* Ngăn các phần tử con xuống dòng */
  border-right: 2px solid #000; /* Hiệu ứng con trỏ gõ code */
  animation: typing 4s steps(70, end), blink-caret 0.75s step-end infinite;
}
.type1{
  font-size: 13px;
  color: teal;
}
/* Hiệu ứng gõ từng chữ */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Hiệu ứng con trỏ */
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}
@keyframes t{
  from {background-size:0 200%}
}
@keyframes b{
  50% {background-position:0 -100%,0 0}
}


@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
